
@import "~bootstrap/scss/bootstrap-utilities";

.pie-counter-container {
	position: relative;
	min-width:50px;;

	.pie-counter {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		height: 400%;
		background-color: #e69679;
		border-radius: 50%;
		aspect-ratio: 1;
		border-color: #ffbd59;
		border-width: 10px;
		border-style: dashed;

		.pie-counter-inner {
			position: absolute;
			left: 50%;
			top: 0%;
			transform: translateX(-50%);
			color: white;
			font-size: 64px;
			font-weight: bold;
		}

		@include media-breakpoint-down(md) {
			height: 200%;
			top: 0%;
			border-width: 5px;

			.pie-counter-inner {
				font-size: 32px;
				top: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
	}

}